<template>
    <div class="minute-privacy-policy w-full bg-white">
        <div class="content-container mx-auto p-6 bg-white leading-tight py-12">
            <h1 class="text-2xl font-bold mb-4">Privacy Policy for Minute</h1>

            <p class="mb-2">Effective Date: January 30th, 2025</p>
            <p class="mb-4">Last Updated: January 30th, 2025</p>

            <p class="mb-4">Welcome to Minute, an audio-based journaling app designed to help you record and reflect on your thoughts securely. Your privacy is important to us, and this Privacy Policy explains how we collect, use, and protect your data.</p>

            <h2 class="text-xl font-semibold mb-2">1. Information We Collect</h2>

            <h3 class="text-lg font-semibold mb-1">a. Personal Data</h3>
            <p class="mb-4">We may collect personal data you provide when using Minute, such as:
            <ul class="list-disc list-inside ml-4">
                <li>Phone number</li>
                <li>Audio recordings you create and store within the app</li>
            </ul>
            </p>

            <h3 class="text-lg font-semibold mb-1">b. Usage Data</h3>
            <p class="mb-4">We may collect information about your interactions with the app, including:
            <ul class="list-disc list-inside ml-4">
                <li>App usage analytics (e.g., session duration, feature usage).</li>
                <li>Device information (e.g., device model, OS version).</li>
            </ul>
            </p>

            <h3 class="text-lg font-semibold mb-1">c. Automatically Collected Data</h3>
            <p class="mb-4">To improve the app experience, we may collect technical data such as:
            <ul class="list-disc list-inside ml-4">
                <li>Log files, crash reports, and diagnostic information.</li>
            </ul>
            </p>

            <h2 class="text-xl font-semibold mb-2">2. How We Use Your Information</h2>
            <p class="mb-4">We use your data to:
            <ul class="list-disc list-inside ml-4">
                <li>Provide and improve the Minute experience.</li>
                <li>Store and manage your audio journal entries.</li>
                <li>Enhance app performance and troubleshoot issues.</li>
                <li>Communicate important updates or support messages.</li>
            </ul>
            </p>

            <h2 class="text-xl font-semibold mb-2">3. Data Storage and Security</h2>
            <p class="mb-4">Your audio journals are stored securely and are accessible only to you.
            We implement industry-standard encryption and security measures.
            We do not sell, trade, or rent your personal data to third parties.</p>

            <h2 class="text-xl font-semibold mb-2">4. Third-Party Services</h2>
            <p class="mb-4">We may use third-party services for analytics, storage, or app functionality. These services have their own privacy policies, and we encourage you to review them.</p>

            <h2 class="text-xl font-semibold mb-2">5. Your Rights and Choices</h2>
            <p class="mb-4">You have the right to:
            <ul class="list-disc list-inside ml-4">
                <li>Access, update, or delete your data within the app.</li>
                <li>Request data deletion by contacting us.</li>
                <li>Manage app permissions for microphone, location, and storage access.</li>
            </ul>
            </p>

            <h2 class="text-xl font-semibold mb-2">6. Changes to This Privacy Policy</h2>
            <p class="mb-4">We may update this policy periodically. Any changes will be posted within the app, and we encourage you to review this page regularly.</p>

            <h2 class="text-xl font-semibold mb-2">7. Contact Us</h2>
            <p class="mb-4">If you have any questions about this Privacy Policy, please contact us at:</p>
            <p>Email: <a href="mailto:jacob@hammerle.us" class="text-blue-500">jacob@hammerle.us</a></p>
            <p>Website: <a href="https://hammerle.us/minute" class="text-blue-500">https://hammerle.us/minute</a></p>
        </div>
    </div>
</template>
  
<script>
    export default {
        name: 'privacyPolicy',
        components: {}
    }
</script>

<style lang="css">
.minute-privacy-policy {
    font-family: Verdana, sans-serif;

    .content-container {
        max-width: 800px;
        margin: 0 auto;
        text-align: left;
    }
}
</style>